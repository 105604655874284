/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    ol: "ol",
    li: "li",
    strong: "strong",
    ul: "ul",
    img: "img"
  }, _provideComponents(), props.components), {TableOfContents, LandingPageCta, SideBySide, MapInfo, TwoColCta, ThreeColCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!MapInfo) _missingMdxReference("MapInfo", true);
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  if (!ThreeColCta) _missingMdxReference("ThreeColCta", true);
  if (!TwoColCta) _missingMdxReference("TwoColCta", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "telehealth-for-hearing-loss-offers-easier-access-to-quality-hearing-care",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#telehealth-for-hearing-loss-offers-easier-access-to-quality-hearing-care",
    "aria-label": "telehealth for hearing loss offers easier access to quality hearing care permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Telehealth for hearing loss offers easier access to quality hearing care"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "As time goes on, more and more people across the U.S. are turning to their smartphones and computers to meet with their doctors over video. In fact, 88 percent of consumers said that by May 2021, they had used telemedicine (telehealth) at some point since the COVID-19 pandemic started in 2020."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "But telemedicine isn’t just for standard healthcare appointments anymore. Some hearing care professionals have evolved their services to be able to offer telehealth for hearing loss and remote hearing care solutions to meet people where they’re at — home. People with hearing loss can now access world-class hearing care and top hearing professionals without ever leaving their homes."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "telemedicine-for-hearing-loss-what-it-is-and-how-it-works",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#telemedicine-for-hearing-loss-what-it-is-and-how-it-works",
    "aria-label": "telemedicine for hearing loss what it is and how it works permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Telemedicine for hearing loss: What it is and how it works"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Telemedicine for hearing loss, also known as teleaudiology or tele audiology, allows people to receive audiology/hearing care with an audiologist or other hearing care professional through a tablet or computer with a camera. This means that people no longer have to travel to a traditional clinic or meet with a hearing care specialist in-person."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Teleaudiology appointments can range from a virtual hearing screening on a website to a sophisticated “remote” video appointment. Remote hearing care appointments use specialized equipment that delivers the same results as in-person appointments."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Teleaudiology is useful in a broad range of areas, including aural rehabilitation (involves creating a hearing loss management plan to help people navigate life with hearing loss), tinnitus (ringing/buzzing in the ears) counseling, and remote hearing tests."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Teleaudiology can be delivered in two ways:"), "\n", React.createElement(_components.ol, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Asynchronous teleaudiology doesn’t involve real-time interaction with a specialist. It instead involves taking a ", React.createElement(_components.a, {
    href: "/hearing-test/",
    className: "c-md-a"
  }, "hearing test online"), ". Results are forwarded to a hearing care professional to interpret, who then sends recommendations back to the patient through email."), "\n"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Synchronous teleaudiology offers real-time interaction through two-way video conferencing (similar to Zoom) with a trained technician or audiology assistant, who performs testing as required by the audiologist. This form of tele-audiology can address a wide range of hearing care needs, including hearing aid fittings, audiometry (tests your ability to hear sounds), and counseling. ", React.createElement(_components.strong, null, "hear.com’s Clinic-in-a-Box®"), " teleaudiology solution follows this method."), "\n"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing care specialists may use a combination of asynchronous and synchronous tele-audiology methods to best suit their needs and those of their patients."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "However, during the Covid-19 pandemic in 2020, synchronous teleaudiology notably grew in popularity. It allowed those with hearing loss to still access quality care they otherwise would have had to delay. Using equipment shipped to their homes, people were able to test their hearing from the safety of home with the help and guidance of a hearing care professional during a video meeting. Convenience and safety have been major factors in the success and growth of tele-audiology and telemedicine in general."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "teleaudiology-benefits",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#teleaudiology-benefits",
    "aria-label": "teleaudiology benefits permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Teleaudiology benefits"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Convenience"), ": Many people in the U.S. lack convenient access or transportation to care. Hearing care centers and teleaudiology doctors may sometimes be located 60+ miles away and require frequent appointments for hearing aid fittings and adjustments. When you have these challenges in addition to hearing loss, telehealth appointments can truly be life changing."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Safety"), ": Remote hearing care gained popularity in 2020 during the Covid-19 pandemic. For those in need of hearing care, telemedicine offered the safety factor — quality care from the comfort of home, without risk of getting sick."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Less travel"), ": Teleaudiology completely removes the need to travel to and from appointments. For example, ", React.createElement(_components.a, {
    href: "/",
    className: "c-md-a"
  }, "hear.com's"), " Clinic-in-a-Box teleaudiology solution is shipped right to your home, so you save on time and gas while also avoiding today’s traffic frustrations."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Better outcomes"), ": People using teleaudiology, notably hear.com’s Clinic-in-a-Box, are experiencing better outcomes because they can provide feedback on the daily sounds they’re exposed to in their own homes during their appointments. This allows for a more personalized experience and accurate programming of their ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "hearing aids"), " right on the spot. Being able to talk to friends and family, listen to the TV, and let the specialist know what sounds good and what doesn’t results in a better experience for the patient, especially on the first day of wear."), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearcoms-clinic-in-a-box-teleaudiology-solution",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearcoms-clinic-in-a-box-teleaudiology-solution",
    "aria-label": "hearcoms clinic in a box teleaudiology solution permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "hear.com’s Clinic-in-a-Box® teleaudiology solution"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/Teleaudiology.jpg",
    alt: "Teleaudiology for hearing loss by hear.com",
    className: "c-md-img"
  })), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hear.com’s Clinic-in-a-Box teleaudiology solution is the world’s first teleaudiology program that delivers high-quality virtual hearing care to anyone in America who\nneeds it. And, you don’t have to be tech savvy to use it."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Here’s how it works and how you can schedule an appointment and try your hearing aids for 45 days — with a money-back guarantee:"), "\n", React.createElement(_components.ol, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Fill out our brief online questionnaire by clicking the link at the bottom of this page. Then, be sure to answer the phone when we call to speak briefly to one of our hearing professionals about your hearing loss. There is never any pressure during this call. We believe that hearing health is serious and deserves a live call to ensure we’re giving you the right hearing aids for your lifestyle and budget."), "\n"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Our hearing specialist will help you secure your 45-day hearing aid trial and then mail you the Clinic-in-a-Box. You’ll receive a teleaudiology tablet (similar to an iPad or other standard tablet) that’s pre-programmed for your appointment, easy-to-use hearing test equipment, and your new hearing aids."), "\n"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Our support team will call you before the appointment to walk you through how to use the equipment, connect to the Internet, and log in to the tablet. They’ll also answer all your questions so you’re completely comfortable before the appointment. We’re dedicated to helping you succeed and have a great experience. Most of all, we make it easy."), "\n"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "On the day of your appointment, you’ll log in to the tablet to meet with one of our top hearing care professionals. They’ll walk you through the hearing test and then fit your hearing aids, based on your testing and personal preferences. This means you can start wearing your hearing aids immediately in your everyday life."), "\n"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Remember: It takes about 30 days for your brain to adapt to wearing hearing aids, but we give you 45 days to fully try them out."), "\n", React.createElement(LandingPageCta, {
    copy: "Sign up for a 45-day trial",
    classes: "c-cta--content"
  }), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/Teleaudiology-clinic-in-a-box.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Customers recommend hear.com teleaudiology appointments because they:")), React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Never have to leave their homes for appointments."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Can get a hearing exam and fitting through video on a special tablet."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Get personalized, caring treatment from the best hearing specialists in the U.S."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Have proven better results long term."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Receive the world’s best and award winning aftercare and lifetime support."), "\n")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Teleaudiology Video Conferencing Tips")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you’re someone who has been using video conferencing tools like Zoom for years, great! If you’re new to it, don’t worry. We’re here to help and will be beside you every step of the way."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Here are some tips to have the best experience over video. During the appointment:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Make sure your laptop or tablet is fully charged or connected to power during the\nvideo call."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Wear noise canceling headphones."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Find a quiet space in your home."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Turn off any background noise, such as a TV."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Consider having one person in the room with you so they can help you with the computer or when the doctor is having you test your new hearing aids, if needed."), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "We’ve helped teleaudiology patients succeed with at-home hearing care, and we feel confident we can help you, too."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "why-hearcom",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#why-hearcom",
    "aria-label": "why hearcom permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Why hear.com"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hear.com’s success has come from putting you, the customer, first. For more than a decade, we’ve served more than 1 million people worldwide virtually and in-person with a mission of helping everyone improve their quality of life through better hearing."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Our hearing care specialists have all been rigorously trained to get you started and assist with any situation. And hear.com’s expert teleaudiology doctors — with an average of 14 years of clinical experience — are hand-picked by us. Our sole focus is you and your hearing health."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you're ready to move forward with a convenient teleaudiology appointment at the end of the initial call, great! If not, that's OK. Your specialist will give you their direct phone number so you can call them back when you're ready. But with affordable payment plans and a no-risk 45-day trial, there is no reason to delay better hearing."), "\n", React.createElement(MapInfo), "\n", React.createElement(LandingPageCta, {
    copy: "TELEAUDIOLOGY APPOINTMENT",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "our-best-recommendations",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#our-best-recommendations",
    "aria-label": "our best recommendations permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Our Best Recommendations"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Two of the best hearing aids on the market today include the Horizon Go IX and the Horizon Mini IX, both which are part of the exclusive Horizon IX line of hearing aids by hear.com. No matter if you have mild or severe hearing loss, one of these state-of-the-art devices is sure to work for you."), "\n", React.createElement(TwoColCta, {
    imgSrc1: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ix-hearing-aids.png",
    copy1: "Horizon Go IX",
    url1: "/hearing-aids/horizon/go-ix/",
    imgSrc2: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/mini-ix-hearing-aids.png",
    copy2: "Horizon Mini IX",
    url2: "/hearing-aids/horizon/mini-ix/"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "resources",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#resources",
    "aria-label": "resources permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Resources"), "\n", React.createElement(ThreeColCta, {
    ImgSrc1: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/women-with-tablet.jpg",
    ContentTitle1: "Hearing Test",
    ContentCopy1: "Check how is your hearing in less than 5 minutes.",
    Url1: "/hearing-test/",
    ImgSrc2: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-aids-behind-ear-blur.jpg",
    ContentTitle2: "Hearing Loss",
    ContentCopy2: "There are several types of hearing loss and many different cause.",
    Url2: "/hearing-loss/",
    ImgSrc3: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ax-grey-blur.jpg",
    ContentTitle3: "Hearing Aids",
    ContentCopy3: "Connect you to the world around you with our wide range of devices.",
    Url3: "/resources/hearing-aids/"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
